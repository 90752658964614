import logoImage from '../img/logo.png'
import '../css/logo.css'


function Logo () {

    return (
        <div className="logo-image-container">
            <img className="logo-image" src = {logoImage} alt = 'error'/>
        </div>
    )

};

export default Logo;