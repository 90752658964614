import chatIcon from '../img/chat_icon.png'
import telegramImage from '../img/telegram_icon.png'
import '../css/buttons.css'


function Buttons () {

    
    const webApp = window.Telegram.WebApp;
    

    return (
        <div className="button-section">
            <div className="button-manager-container" onClick={() => webApp.openTelegramLink('https://t.me/ExchangeeSupport')}>
                <div className="button-text">
                    Совершить обмен
                </div>
                <div className="button-image-container">
                    <img src = {chatIcon} className='button-image' alt='error'/>
                </div>
            </div>
            <div className="button-chanel-container" onClick={() => webApp.openTelegramLink('https://t.me/ThaiExchangee')}>
                <div className="button-text">
                    Перейти в канал
                </div>
                <div className="button-image-container-tg" >
                    <img src = {telegramImage} className='button-image' alt='error'/>
                </div>
            </div>
        </div>
    )
};

export default Buttons;