import Logo from "./components/Logo";
import Buttons from "./components/Buttons";
import { useState, useEffect } from 'react'
import restApiHost from ".";


function App() {

  const [isRequestSended, setRequestSended] = useState(false);
  const webApp = window.Telegram.WebApp;
  webApp.ready();
  webApp.expand();

  useEffect(() => {

    async function fetchData() {
      try {
          const startParam = webApp.initDataUnsafe.start_param;
          console.log(startParam);
          const username = webApp.initDataUnsafe.user.username;
          console.log(username);
          const chatId = webApp.initDataUnsafe.user.id;
          console.log(chatId);
          const data = {
            username: username,
            chat_id: chatId,
            owner_chat_id: startParam
          }
          console.log(data);
          
          await fetch(restApiHost + '/api/add_referal/', {
            method: 'POST', // Указываем метод запроса
            headers: {
              'Content-Type': 'application/json', // Указываем, что данные будут в формате JSON
            },
            body: JSON.stringify(data) // Преобразуем объект в строку JSON
          });
          setRequestSended(true);
          console.log('Успешно отправили запрос')
        
        } catch (error) {
          console.error('Ошибка при отправке запроса:', error);
        };
      }

    if (!isRequestSended){
      fetchData();
    }
  }, [isRequestSended]);
  return (
    <div className="app">
      <Logo/>
      <Buttons/>
    </div>
  );
}

export default App;
